body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  width: 100vw;
  height: 100vh;
}
.IndexBlock {
  position: absolute;
  top: 5vh;
  left: 2.5vw;

  width: 95vw;
  height: 90vh;
  border-left: 0.1vh solid black;
  border-right: 0.1vh solid black;
  box-shadow: 0.5vh 0.5vw 2vh #eaeff2;
}

.navbar {
  height: 8vh;
}
.logo-image {
  padding: 2vh 2vw;
  height: 3vh;
}

.vertical-text {
  position: absolute;

  right: 3.5vw;
  bottom: 1vh;

  height: 2vw;

  -webkit-transform: rotate(90deg);

          transform: rotate(90deg);
  -webkit-transform-origin: right bottom 0;
          transform-origin: right bottom 0;
}

.Nav-List {
  position: absolute;
  width:10vw;
  height:10vh;
  list-style: disc outside none;
  list-style: initial;
  list-style-type: none;
  align-self: center;
  top:40vh;
  left:0vh;
}
.Nav-List li {
  padding: 2.5vh 0;
}
.Nav-List li span{
  position: absolute;
  padding:0.3vh 0 0 0;
  left:-0.3vw;
  width:1vh;
  height: 1vh;
  background: black;

}
.Menu-Text
{
  font-size: 3vh;
  font-family: "Teko";
  position: absolute;
  left:1vw;
  width:10vw;
  height: 5vh;


}

.Nav-List li:hover{


 -webkit-transition: ease-in 1s;


 transition: ease-in 1s;
  left:0vw;

}
.main-index {
  height: 82vh;


}
.main-index h1
{
  padding-left: 30vw;
  padding-top: 30vh;
  padding-bottom: 5vh;
  text-align: left;
  font-size:4vh;
  font-family: "Poppins";
  font-weight: 400;
}

.main-index p
{
  padding-left: 30vw;
  text-align: left;
  font-size:7vh;
  font-family: "Poppins";
  font-weight: 800;
}


/*-------------------------------About Page-------------------------------*/
.AboutBlock {
  position: absolute;
  top: 5vh;
  left: 2.5vw;
  background: black;

  width: 95vw;
  height: 90vh;

  box-shadow: 0.5vh 0.5vw 2vh #eaeff2;
}
.navbar-about {
  height: 8vh;
}

.one-line-intro {
  font-family: "Vidaloka";
  display: grid;
  grid-template-columns: 47.5vw 47.5vw;
}
.one-line-intro h1{
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  -webkit-transform-origin: left top 0;
          transform-origin: left top 0;
  position: absolute;
  left:35vw;
  text-shadow: 0.3vh 0.3vw 5vh #E8EEF1;
  bottom:-5vh;

  color:white;
  font-family: "Teko";
  font-weight: 900;
  font-size: 20vh;
  letter-spacing: 4vh;

}

.one-line-intro p{
  letter-spacing: 0.31vh;
  line-height: 5vh;
  padding-top: 10vh;
  padding-right: 8vw;
  font-family: "Playfair Display";
  font-size: 3vh;
  text-align: left;
}

.Skills {
  padding-right: 10vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.Skills li {
  margin: 2vh;
  height: 8vh;
  width: 8vh;
}
.Skills li img {
  width: 100%;
  height: inherit;
}

.ppfoto-div {
  grid-column: 1/2;
  grid-row:1/3;
  height: 60vh;
  width: 40vh;
  margin: 5vh 5vw;
}

.ppfoto {
  box-shadow: 0.1vh 0.1vw 1vh black;

  height: 100%;
  object-fit: cover;
}

/*-----------------------------Contact Page-----------------------------------*/

.Contact-div {
  display: grid;
  grid-template-rows: 30vh 25vh 15vh 5vh;
  grid-template-columns: 47.5vw 47.5vw;
}
.Social-Links {
  grid-column: 2/3;
  grid-row: 2/3;
  color: black;
  text-align: right;
  padding-right: 10vw;
}
a {
  text-decoration: none;
  color: black;
}
.Social-Links ul li {
  font-family: "Teko";
  font-size: 4vh;
  margin: 3vh 0;
}
.Social-Links ul li a:hover {
  color: #00E0D4;
  -webkit-transition: color .35s ease-in-out;
  transition: color .35s ease-in-out;

}
.Contact-Me-Heading {
  grid-row: 1/2;
  text-align: left;
  align-content: flex-end;
  padding-left: 8vw;
  padding-top: 15vh;
  font-size: 11vh;
  letter-spacing: 1vh;
  font-family: "Anton";
}
.Contact-Me-Heading:hover {
  color:#00E0D4;
}

.Contact-Me-Text {
  grid-row: 2/3;
  font-size: 3vh;
  font-family: "Vidaloka";
  padding-left: 8vw;
  line-height: 5vh;
}
.date-high {
  font-family: "Anton";
}

.Contact-Me {
  grid-column: 1/3;

  text-decoration: none;
  color: white;
  font-family: "Vidaloka";
  font-size: 5vh;
  line-height: 8vh;
  letter-spacing: 0.4vh;
  text-align: center;
  align-items: center;
  background-color: black;
  height: 8vh;
  width: 26vw;
  box-shadow: 0.1vh 0.1vw 1vh #000022;
  margin: auto;
}
.Contact-Me:hover {
  background: #00E0D4;
  color:black;
  -webkit-transition: background .35s ease-in-out;
  transition: background .35s ease-in-out;
}

.Copyright-Text {
  grid-column: 1/3;
  text-decoration: none;
  text-align: center;
  color: black;
  font-family: "Poppins";
  font-weight: 200;
  font-size: 1vh;
}

/*------------------------------------------------------------------------------------------------------*/

